i<template>
	<section id="calendarItemsStepPage">
		<article class="top-section">
			<div>
				<h1>Nieuwsbrief samenstellen</h1>
				<label>Agenda items zoeken</label>
				<el-date-picker
      		v-model="newsItemSearch.date"
					id="newsitemsDatepicker"
					type="daterange"
					range-separator="Tot"
					start-placeholder="Begin datum"
					end-placeholder="Eind datum"
					>
				</el-date-picker>
			</div>
			<div>
				<ul class="newsletter-steps">
					<router-link :to="{name: 'NewsItemStep'}" tag="li">
						<span>1</span>
						<div>Nieuwsberichten</div>
					</router-link>
					<router-link :to="{name: 'AdvertisementStep'}" tag="li">
						<span>2</span>
						<div>Advertentie</div>
					</router-link>
					<router-link :to="{name: 'CalendarItemStep'}" tag="li">
						<span>3</span>
						<div>Agenda items</div>
					</router-link>
				</ul>
				<div class="btn btn-primary" @click="saveNewsletter" id="backToNewsItemsBtn">
					Nieuwsbrief aanmaken
				</div>
				<router-link :to="{name: 'AdvertisementStep'}" tag="div" class="btn btn-transparant">
					<img src="@/assets/left-arrow-orange.svg" alt="">
					Terug naar advertentie
				</router-link>
			</div>
		</article>
		<article id="newItemsSelection">
			<div>
				<h2>Agenda items</h2>
				<ul id="newsletterSearchList">
					<li v-for="calendarItem in filteredCalendarItems" :key="calendarItem.id">
						<div class="newsletterSearchListTitle">
							{{calendarItem.title}}
						</div>
						<div class="addItem" @click="selectedCalendarItems.push(calendarItem)">
							<img src="@/assets/right-arrow.svg" alt="">
						</div>
					</li>
				</ul>
			</div>
			<div id="newsletterSelectedItems">
				<h2>Geselecteerde agenda items</h2>
				<ul class="sortable-list">
					<draggable v-model="selectedCalendarItems" @start="drag=true" @end="drag=false">
							<li v-for="(calendarItem, i) in selectedCalendarItems" :key="calendarItem.id">
								<div class="removeItem" @click="removeSelectedItem(calendarItem.id)">
									<img src="@/assets/left-arrow.svg" alt="">
								</div>
								<div class="newsletterSearchListTitle">
									{{calendarItem.title}}
								</div>
								<div class="itemPosition">
									{{i + 1}}
								</div>
							</li>
					</draggable>
				</ul>
			</div>
		</article>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable'

export default {
	data () {
		return {
			newsItemSearch: {
				date: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)]
			},
			selectedCalendarItems:[]
		}
	},
	watch: {
		'newsItemSearch.date' (date) {
			this.fetchCalendarItems()
		}
	},
	components: {
			draggable,
	},
	computed: {
		...mapGetters({
			newNewsletterItems: 'newsletter/newNewsletterItems',
			calendarItems: 'calendarItem/calendarItems',
			selectedAdvertisementsRight: 'newsletter/selectedAdvertisementsRight',
			selectedAdvertisementsCenter: 'newsletter/selectedAdvertisementsCenter'
		}),
		filteredCalendarItems () {
			let calendarItemIds = this.selectedCalendarItems.map(s => s.id)
			return this.calendarItems.filter(c => !calendarItemIds.includes(c.id))
		}
	},
	created () {
		this.fetchCalendarItems()
	},
	mounted () {
	},
	methods: {
		fetchCalendarItems () {
			let dateFrom = ''
			let dateTill = ''

			if(this.newsItemSearch.date[0]) {
				dateFrom = this.newsItemSearch.date[0].toLocaleDateString("nl-NL")
				dateTill = this.newsItemSearch.date[1].toLocaleDateString("nl-NL")
			}
			this.$store.dispatch('calendarItem/fetchCalendarItems', {begin_date_from: dateFrom , begin_date_till: dateTill, limit: 1000})
		},
		removeSelectedItem (itemId) {
			this.selectedCalendarItems = this.selectedCalendarItems.filter(s => s.id !== itemId)
		},
		saveNewsletter() {
			let newsletter = {
				news_items: [],
				calendar_items: [],
				advertisements: []
			}
			this.newNewsletterItems.forEach(theme => {
				theme.items.forEach(item => {
					newsletter.news_items.push({id: item.id, theme_id: theme.id})
				})
			})

			this.selectedAdvertisementsRight.forEach(advertisement => {
				newsletter.advertisements.push({id: advertisement.id, placement: 'right'})
			})

			this.selectedAdvertisementsCenter.forEach(advertisement => {
				newsletter.advertisements.push({id: advertisement.id, placement: 'center'})
			})
			newsletter.calendar_items = this.selectedCalendarItems.map(s => s.id)

			if(newsletter.news_items.length < 1) {
				this.$notify({
					title: 'Nieuwsbrief aanmaken mislukt',
					message: 'Er zijn geen nieuwsberichten toegevoegd',
					type: 'error',
					position: 'bottom-right'
				})
			} else {
				this.$store.dispatch('newsletter/createNewsletter', newsletter).then((response) => {
					this.$router.push({name: 'Newsletter'})
					this.$notify({
						title: `Nieuwsbrief '${response.newsletter_nr}' aangemaakt`,
						message: 'Nieuwsbrief is succesvol aangemaakt',
						type: 'success',
						position: 'bottom-right'
					})
				})
			}
		}
	}
}
</script>
<style lang="scss">
#app #calendarItemsStepPage {
	height: 100%;
	padding: 30px;
	#backToNewsItemsBtn {
		margin-bottom: 10px;
	}
	#themeSelector {
		h1 {
			margin: 0;
		}
		p {
			margin: 10px 0px 30px;
		}
		.btn {
			margin-top: 30px;
		}
		ul {
			padding: 0;
			list-style-type: none;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 10px;
			grid-row-gap: 10px;
			li {
				padding: 10px 20px;
				border: 1px solid #ccc;
				border-radius: 5px;
				cursor: pointer;
				&:hover {
					border-color: #E04E39;
				}
			}
		}
	}
	> article {
		&.top-section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			h1 {
				margin-bottom: 40px;
			}
			.el-date-editor {
				max-width: 400px;
			}
		}
	}
	#newItemsSelection {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
		h2 {
			margin: 30px 0 10px;
		}
		#newsletterSearchList {
			list-style-type: none;
			padding: 0;
			margin: 0;
			border-top: 2px solid #E04E39;
			li {
				border-bottom: 1px solid #DCDCDC;
				border-left: 1px solid #DCDCDC;
				border-right: 1px solid #DCDCDC;
				display: grid;
				grid-template-columns: 1fr 40px;
				.newsletterSearchListTitle {
					padding: 12px 20px;
				}
				.addItem {
					padding: 10px 0;
					display: grid;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					background-color: rgba(0, 0, 0, 1);
				}
			}
		}
		#newsletterSelectedItems {
			b {
				display: block;
			}
			ul {
				list-style-type: none;
				padding: 0;
				margin: 10px 0 20px;
				border-top: 2px solid #E04E39;
				li {
					border-bottom: 1px solid #DCDCDC;
					border-left: 1px solid #DCDCDC;
					border-right: 1px solid #DCDCDC;
					background-color: white;
					display: grid;
					grid-template-columns: 40px 1fr 40px;
					cursor: move;
					.newsletterSearchListTitle {
						padding: 12px 20px;
					}
					.removeItem {
						padding: 10px 0;
						text-align: center;
						cursor: pointer;
						background-color: rgba(0, 0, 0, 1);
						display: grid;
						align-items: center;
						justify-content: center;
					}
					.itemPosition {
						font-weight: bold;
						font-size: 18px;
						padding: 10px 0;
						display: grid;
						align-items: center;
						justify-content: center;
						cursor: move;
						border-left: 1px solid #DCDCDC;
					}
				}
			}
		}
	}
}
</style>